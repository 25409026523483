import HeaderAppyShop, {getComponentStaticProps, HeaderProps} from '../../components/header/headerAppyShop'
import FooterAppyShop from '../../components/footer/footerAppyShop'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { config } from '../../config-merchant'
import { useTranslation } from 'next-i18next';
import Meta from '../../components/meta/meta';
import { appyShopConfig } from '../../config';

export default function Home(props: { headerProps: HeaderProps }) {

    const { i18n } = useTranslation('common');

    return (
        <>
            <Meta
                title={""}
                description={config.shopSlogan[i18n.language]}
                image={config.merchantDomain + config.defaultMetaImage}
                metaRobots={true}
            />
            <HeaderAppyShop activepage={'home'} headerProps={props.headerProps} />

            <div className={'wrapper-home'}>

                <p className={'teaser'}>Le respect des recommandations de la santé bucco-dentaire</p>

                <div className={'player-intro'}>
                    <div className={'video'}>
                        <video width="auto" height="auto" autoPlay muted disablePictureInPicture playsInline>
                            <source src="assets/videos/rtb-introduction-v3.mp4" type="video/mp4" />
                            <source src="assets/videos/rtb-introduction-v3.mov" type="video/mov" />
                            Your browser does not support the video tag.
                        </video>
                    </div>
                </div>

                <div className={"twister"}>
                    <img src={"assets/svg/twister.svg"} alt={"RollerToothbrush"} width={"79"} height={"48"}/>
                </div>

                <div className={"presentation"}>
                    <div className={"presentation-tv"}>
                        <img src={"assets/images/portrait-tv.png"} alt={"Thierry Vallet"} width={"184"} height={"184"}/>
                        <p>Thierry Vallet</p>
                        <p>Chirurgien dentiste</p>
                        <p>Président de ConcepTeeth & Inventeur du concept</p>
                    </div>
                    <div className={"first-p"}>
                        <p><span className={"p-circle"}></span><strong>Mon métier, c’est ma passion</strong> et j’ai toujours essayé de répondre à l’attente de mes patients en termes de soins, d’esthétique et de prophylaxie.</p>
                        <p>Cette <strong>recherche de la perfection</strong> m’a conduit à imaginer le concept de la <strong>brosse à dents à rouleau, avec rotation continue</strong>, qui est la réponse aux questions et aux commentaires de mes patients pendant plus de 35 ans.</p>
                    </div>
                    <div className={"second-p"}>
                        <p>J’ai dessiné les premières esquisses en 2008 et le <strong>concept a été primé au Concours Lépine en 2010</strong>.</p>
                        <p>J’ai rencontré Éric, le directeur général de la société, en 2011.</p>
                        <p><strong>Nous avons fondé la Société ConcepTeeth en 2018</strong> et aujourd’hui, nous sommes heureux de vous proposer cette <strong>brosse qui représente l’excellence</strong> en matière de brosse à dents électriques.</p>
                        <div className={"line"}></div>
                    </div>
                </div>

                <div className={"button-center"}>
                    <a href={"/our-values"}><button>Nos valeurs</button></a>
                </div>

                <div className={"products-categories"}>

                    <div className={"category"}>
                        <div>
                            <a href={"/choose-your-brush"}>
                                <img src={"assets/images/product-brushs.png"} alt={"Brosses"} width={"330"} height={"330"}/>
                            </a>
                        </div>
                        <p>Brosses à dents</p>
                    </div>
                    <div className={"category"}>
                        <div>
                            <a href={"/choose-your-brush"}>
                                <img src={"assets/images/product-heads.png"} alt={"Brossettes"} width={"330"} height={"330"}/>
                            </a>
                        </div>
                        <p>Brossettes</p>
                    </div>
                    <div className={"category"}>
                        <div>
                            <a href={"/choose-your-brush"}>
                                <img src={"assets/images/product-accessories.png"} alt={"Accessoires"} width={"330"} height={"330"}/>
                            </a>
                        </div>
                        <p>Accessoires</p>
                    </div>
                </div>

                <div className={"twister"}>
                    <img src={"assets/svg/twister.svg"} alt={"RollerToothbrush"} width={"79"} height={"48"}/>
                </div>

                <div className={"advantages"}>

                    <div className={"titles"}>
                        <p>Les avantages de la brosse à rouleau ?</p>
                        <div>
                            <p>C’est le respect des recommandations de la santé bucco-dentaire</p>
                            <div className={"line"}></div>
                        </div>
                    </div>

                    <div className={"infos"}>

                        <div className={"flex-blocks-top"}>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-roller.svg"} alt={"Rouleau"} width={"61"} height={"69"}/>
                                <p><span className={"p-circle"}></span> Changement automatique de direction du rouleau. On brosse toujours dans le bon sens. "Du rouge vers le blanc", de la gencive vers la dent.</p>
                            </div>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-brush.svg"} alt={"Brossage"} width={"75"} height={"57"}/>
                                <p><span className={"p-circle"}></span> Grande surface de brossage et une tête plus petite.</p>
                            </div>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-teeth.svg"} alt={"Sensation"} width={"47"} height={"74"}/>
                                <p><span className={"p-circle"}></span> Nouvelle sensation exclusive et addictive de massage.</p>
                            </div>
                        </div>

                        <div className={"flex-blocks-bottom"}>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-plume.svg"} alt={"Brossage doux"} width={"98"} height={"42"}/>
                                <p><span className={"p-circle"}></span> Brossage doux et respectueux avec ses poils hélicoïdaux et sa tête souple.</p>
                            </div>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-sound.svg"} alt={"Silence"} width={"55"} height={"55"}/>
                                <p><span className={"p-circle"}></span> Absence de toute vibration et de bruit agressifs.</p>
                            </div>
                            <div className={"block"}>
                                <img src={"assets/svg/icon-clock.svg"} alt={"1 minute"} width={"84"} height={"84"}/>
                                <p><span className={"p-circle"}></span> Brossage en 1 minute seulement !</p>
                            </div>
                        </div>

                    </div>

                    <div className={"button-coach button-center"}>
                        <img src={"assets/svg/icon-coach.svg"} alt={"Conseil du Coach"} width={"59"} height={"79"}/>
                        <a href={"/advice-from-the-coach"}><button>Conseils du coach</button></a>
                    </div>
                </div>

                <div className={"products-brushs"}>
                    <div className={"product-brush"}>
                        <img src={"assets/images/img-premium-square.png"} alt={"Brosse Premium"} width={"310"} height={"310"}/>
                        <p>La brosse Premium</p>
                        <p>XX €</p>
                    </div>
                    <div className={"product-brush"}>
                        <img src={"assets/images/img-mid-square.png"} alt={"Brosse Mid"} width={"310"} height={"310"}/>
                        <p>La brosse Mid</p>
                        <p>XX €</p>
                    </div>
                    <div className={"product-brush"}>
                        <img src={"assets/images/img-basic-square.png"} alt={"Brosse Basic"} width={"310"} height={"310"}/>
                        <p>La brosse Basic</p>
                        <p>XX €</p>
                    </div>
                </div>

                <div className={"button-center"}>
                    <a href={"/choose-your-brush"}><button>Voir tous les produits</button></a>
                </div>

                <div className={"constat"}>
                    <div className={"titles"}>
                        <p>Le constat clinique</p>
                        <p>La technologie du rouleau répond à toutes les problématiques du brossage <div className={"line"}></div></p>
                    </div>
                    <div className={"button"}>
                        <img src={"assets/svg/icon-scientific.svg"} alt={"Analyse scientifique"} width={"59"} height={"68"}/>
                        <a href={"/scientific-analysis"}><button>Lire l'analyse scientifique</button></a>
                    </div>
                </div>

            </div>

            <FooterAppyShop />
        </>

    )
}

export const getStaticProps = async ({ locale }) => {
    if (config.menu.some(item => item.path === 'home') || config.footerLink.some(item => item.url === '/home')) {
        return {
            props: {
                ...await serverSideTranslations(locale, ['common']),
                headerProps: await getComponentStaticProps(locale)
            },
            revalidate: appyShopConfig.revalidateData
        }
    } else {
        return {
            notFound: true
        }
    }
}



